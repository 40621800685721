<template>
  <div>
    <div class="card">
      <div class="title-box flex flex-jb">
        <div class="title" style="font-weight: bold;">{{ cardTitle }}</div>
        <div v-if="dateType == '01'">
          <el-date-picker
            v-if="dataType != '06'"
            :editable="false"
            v-model="addTotalDate"
            type="monthrange"
            range-separator="~"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            size="small"
            :picker-options="pickerOptions"
            value-format="yyyy-MM"
            :default-time="['00:00:00', '23:59:59']"
            @change="addTotalDateChange"
            :clearable="false"
          >
          </el-date-picker>
          <el-date-picker
            v-else
            v-model="addTotalDate06"
            type="month"
            size="small"
            :editable="false"
            :picker-options="pickerOptions"
            format="yyyy-MM"
            value-format="yyyy-MM"
            placeholder="选择月份"
            @change="addTotalDateChange"
            :clearable="false"
          >
          </el-date-picker>
        </div>
        <div class="data-quarter flex" v-if="dateType == '02'">
          <quarter-date-picker
            v-if="dataType != '06'"
            :quarterValue="quarterStartValue"
            :pickerOptions="quarterPickerOptions"
            @quarterChange="(e) => handleChangeQuarter(e, 'start')"
          ></quarter-date-picker>
          <span style="margin: 0 4px;" v-if="dataType != '06'">~</span>
          <quarter-date-picker
            :quarterValue="quarterEndValue"
            :pickerOptions="quarterPickerOptions"
            @quarterChange="(e) => handleChangeQuarter(e, 'end')"
          ></quarter-date-picker>
        </div>
        <div class="data-year" v-if="dateType == '03'">
          <el-date-picker
            v-if="dataType != '06'"
            v-model="curStartYear"
            type="year"
            size="small"
            :editable="false"
            :picker-options="startPickerOptions"
            format="yyyy"
            value-format="yyyy"
            placeholder="选择年份"
            @change="
              (e) =>
                handleChangeYear({
                  curStartYear: e,
                  curEndYear: this.curEndYear,
                })
            "
            :clearable="false"
          >
          </el-date-picker>
          <span style="margin: 0 4px;" v-if="dataType != '06'">~</span>
          <el-date-picker
            v-model="curEndYear"
            type="year"
            size="small"
            :editable="false"
            :picker-options="endPickerOptions"
            format="yyyy"
            value-format="yyyy"
            placeholder="选择年份"
            @change="
              (e) =>
                handleChangeYear({
                  curStartYear: this.curStartYear,
                  curEndYear: e,
                })
            "
            :clearable="false"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="card-body">
        <div class="flex-as" style="padding: 0 25px" v-if="latelyList.length">
          <div class="txt-333" v-if="lately">
            {{ lately.calendarYear }}年{{ lately.calendarMonth }}月
          </div>
          <div class="flex fs-12 flex1 flex-jb">
            <div
              class="data-item"
              v-for="(item, index) in latelyList"
              :key="index"
            >
              <div class="txt-2 pl-7" v-if="item.text">{{ item.text }}</div>
              <div class="pl-7" v-if="item.text" style="margin:12px 0">
                <span class="txt-333 fs-16">{{
                  lately ? lately[item.key] : "--"
                }}</span>
                <span class="txt-2">{{ item.unit }}</span>
              </div>
              <div class="compare flex" v-if="item.text">
                <div
                  class="compare-tag mr-5"
                  :class="
                    lately && lately[item.compare] == '01' ? 'green' : 'red'
                  "
                >
                  <img
                    style="width:16px;height:10px;margin-right:2px"
                    v-if="lately ? lately[item.compare] == '01' : ''"
                    src="../../../assets/images/icon_ssqs.png"
                    alt=""
                  />
                  <img
                    style="width:16px;height:10px;margin-right:2px"
                    v-if="lately ? lately[item.compare] == '02' : ''"
                    src="../../../assets/images/icon_xjt_.png"
                    alt=""
                  />
                  <span
                    >{{ lately ? numHandle(lately[item.qoq]) : "--"
                    }}{{ item.unit }}</span
                  >
                </div>
                <div class="txt-2">较上月</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="dataType == '01'">
          <div class="Polyline" id="chartBoxDeal1"></div>
          <div
            class="txt-2"
            style="padding-left:50px;margin-top:-40px;line-height: 20px;"
          >
            <div class="fs-12">询价量：店员每月下的订单数量</div>
            <div class="fs-12">成交量：店员每月成交的订单数量</div>
          </div>
        </div>
        <div v-if="dataType == '02'">
          <div class="Polyline" id="chartBoxDeal2"></div>
          <div
            class="txt-2"
            style="padding-left:50px;margin-top:-40px;line-height: 20px;"
          >
            <div class="fs-12">回收额：成交订单的回收商交易金额总和</div>
            <div class="fs-12">用户成交额：成交订单的用户成交金额总和（含换新补贴）</div>
            <div class="fs-12">
              回收毛利：包含公司净利、员工奖励
            </div>
          </div>
        </div>
        <div v-if="dataType == '03'">
          <div class="Polyline" id="chartBoxDeal3"></div>
          <div
            class="txt-2"
            style="padding-left:50px;margin-top:-40px;line-height: 20px;"
          >
            <div class="fs-12">回收单价：回收商交易总额/成交订单量</div>
            <div class="fs-12">成交客单价：用户成交总额/成交订单量（用户成交金额含换新补贴）</div>
            <div class="fs-12">单机毛利：回收总利润/成交订单量</div>
          </div>
        </div>
        <div v-if="dataType == '04'">
          <div class="Polyline" id="chartBoxDeal4"></div>
        </div>
        <div v-if="dataType == '05'">
          <div class="Polyline" id="chartBoxDeal5"></div>
          <div
            class="txt-2"
            style="padding-left:50px;margin-top:-40px;line-height: 20px;"
          >
            <div class="fs-12">毛利率：公司净利润/回收交易额*100%</div>
          </div>
        </div>
        <div v-if="dataType == '06'">
          <div class="flex flex-as">
            <div class="Polyline6_1" id="chartBoxDeal6_1"></div>
            <div class="Polyline6_2" id="chartBoxDeal6_2"></div>
          </div>
          <div class="mt-30 plr-25" v-if="dataEchart">
            <div class="table_06">
              <div class="flex table-row">
                <div style="width:100px">成交率：</div>
                <div style="text-align: center;width:200px">
                  {{ dataEchart.dealRate }}%
                </div>
                <div
                  class="compare flex flex1"
                  style="justify-content: flex-end;"
                >
                  <div
                    class="compare-tag mr-5"
                    :class="dataEchart.thanDealRateUp == '01' ? 'green' : 'red'"
                  >
                    <img
                      style="width:16px;height:10px;margin-right:2px"
                      v-if="dataEchart.thanDealRateUp == '01'"
                      src="../../../assets/images/icon_ssqs.png"
                      alt=""
                    />
                    <img
                      style="width:16px;height:10px;margin-right:2px"
                      v-if="dataEchart.thanDealRateUp == '02'"
                      src="../../../assets/images/icon_xjt_.png"
                      alt=""
                    />
                    <span>{{ dataEchart.thanDealRate }}%</span>
                  </div>
                  <div class="txt-2">
                    较{{
                      dateType == "01"
                        ? "上月"
                        : dateType == "02"
                        ? "上季度"
                        : "上年"
                    }}
                  </div>
                </div>
              </div>
              <div class="flex table-row">
                <div style="width:100px">取消率：</div>
                <div style="text-align: center;width:200px">
                  {{ dataEchart.cancelRate }}%
                </div>
                <div
                  class="compare flex flex1"
                  style="justify-content: flex-end;"
                >
                  <div
                    class="compare-tag mr-5"
                    :class="
                      dataEchart.thanCancelRateUp == '01' ? 'green' : 'red'
                    "
                  >
                    <img
                      style="width:16px;height:10px;margin-right:2px"
                      v-if="dataEchart.thanCancelRateUp == '01'"
                      src="../../../assets/images/icon_ssqs.png"
                      alt=""
                    />
                    <img
                      style="width:16px;height:10px;margin-right:2px"
                      v-if="dataEchart.thanCancelRateUp == '02'"
                      src="../../../assets/images/icon_xjt_.png"
                      alt=""
                    />
                    <span>{{ dataEchart.thanCancelRate }}%</span>
                  </div>
                  <div class="txt-2">
                    较{{
                      dateType == "01"
                        ? "上月"
                        : dateType == "02"
                        ? "上季度"
                        : "上年"
                    }}
                  </div>
                </div>
              </div>
              <div class="flex table-row">
                <div style="width:100px">退款/作废率：</div>
                <div style="text-align: center;width:200px">
                  {{ dataEchart.refundAbolishRate }}%
                </div>
                <div
                  class="compare flex flex1"
                  style="justify-content: flex-end;"
                >
                  <div
                    class="compare-tag mr-5"
                    :class="
                      dataEchart.thanRefundAbolishRateUp == '01'
                        ? 'green'
                        : 'red'
                    "
                  >
                    <img
                      style="width:16px;height:10px;margin-right:2px"
                      v-if="dataEchart.thanRefundAbolishRateUp == '01'"
                      src="../../../assets/images/icon_ssqs.png"
                      alt=""
                    />
                    <img
                      style="width:16px;height:10px;margin-right:2px"
                      v-if="dataEchart.thanRefundAbolishRateUp == '02'"
                      src="../../../assets/images/icon_xjt_.png"
                      alt=""
                    />
                    <span>{{ dataEchart.thanRefundAbolishRate }}%</span>
                  </div>
                  <div class="txt-2">
                    较{{
                      dateType == "01"
                        ? "上月"
                        : dateType == "02"
                        ? "上季度"
                        : "上年"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import quarterDatePicker from "./quarterDatePicker";
import moment from "moment";
const echarts = require("echarts");
export default {
  components: {
    quarterDatePicker,
  },
  props: {
    cardTitle: {
      require: true,
      type: String,
    },
    dateType: {
      require: true,
      type: String,
    },
    dataType: {
      require: true,
      type: String,
    },
    entryTime: {
      require: true,
      type: Number,
    },
    latelyList: {
      require: true,
      type: Array,
    },
    lately: {
      require: true,
      type: Object,
    },
    dataEchart: {
      require: true,
    },
  },
  data() {
    return {
      addTotalDate: "",
      quarterDate: "",
      curStartYear: "",
      curEndYear: "",
      pickerOptions: {},
      startPickerOptions: {},
      endPickerOptions: {},
      defaultValue: "",
      monthTime: [],
      addTotalDate06: "",
      quarterStartValue: "",
      quarterEndValue: "",
      quarterPickerOptions: []
    };
  },
  created() {
    console.log(this.timeType);
  },
  mounted() {},
  watch: {
    dateType(val) {
      if (val == "01") {
        this.addTotalDateChange(this.monthTime);
      }
      if (val == "03") {
        this.handleChangeYear({
          curStartYear: this.curStartYear,
          curEndYear: this.curEndYear,
        });
      }
      if (val == "02") {
        this.handleChangeQuarter(
          {
            startQuarter: this.quarterStartValue,
            endQuarter: this.quarterEndValue,
          },
          "all"
        );
      }
    },
    entryTime(val) {
      if (val) {
        this.pickerOptions = {
          // 日期限制
          disabledDate: (time) => {
            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear(); // 获取时间选择器的年份
            let timemonth = time.getMonth() + 1; // 获取时间选择器的月份
            if (timemonth >= 1 && timemonth <= 9) {
              timemonth = "0" + timemonth;
            }
            const elTimeData = timeyear.toString() + timemonth.toString();
            //小于、大于指定时间都不可选
            return (
              elTimeData < moment(val).format("YYYYMM") ||
              elTimeData >= moment(Date.now()).format("YYYYMM")
            );
          },
        };
        const nowDate = new Date(), year = nowDate.getFullYear(), month = nowDate.getMonth()
        const startDate = moment(new Date(year, month - 5, 0)).format("YYYY-MM")
        const endDate = moment(new Date(year, month, 0)).format("YYYY-MM")
        this.addTotalDate = [startDate, endDate];
        this.addTotalDate06 = endDate;
        
        this.getYearPickerOptions(val);
        this.getQuarterPickerOptions(val)
        if (this.dataType == "06") {
          this.addTotalDateChange(this.addTotalDate06);
        } else {
          this.addTotalDateChange(this.addTotalDate);
        }
      }
    },
    dataEchart(arr) {
      this.dataValueY1 = [];
      this.dataValueY2 = [];
      this.dataValueY3 = [];
      this.dataValueX = [];
      if (this.dataType == "01") {
        for (let i = 0; i < arr.length; i++) {
          this.dataValueY1.push({
            upType: arr[i].thanInquiryNumUp,
            upNum: arr[i].thanInquiryNum,
            value: arr[i].inquiryNum,
          });
          this.dataValueY2.push({
            upType: arr[i].thanDealNumUp,
            upNum: arr[i].thanDealNum,
            value: arr[i].dealNum,
          });
          if (this.dateType == "01") {
            this.dataValueX.push(arr[i].calendarMonth + "月");
          } else if (this.dateType == "02") {
            this.dataValueX.push(arr[i].calendarQuarter + "季度");
          } else {
            this.dataValueX.push(arr[i].calendarYear + "年");
          }
        }
        this.creatEchart1();
      }
      if (this.dataType == "02") {
        for (let i = 0; i < arr.length; i++) {
          this.dataValueY1.push(arr[i].dealAmount);
          this.dataValueY2.push(arr[i].finalAmount);
          this.dataValueY3.push(arr[i].dealProfit);
          if (this.dateType == "01") {
            this.dataValueX.push(arr[i].calendarMonth + "月");
          } else if (this.dateType == "02") {
            this.dataValueX.push(arr[i].calendarQuarter + "季度");
          } else {
            this.dataValueX.push(arr[i].calendarYear + "年");
          }
        }
        this.creatEchart2();
      }
      if (this.dataType == "03") {
        for (let i = 0; i < arr.length; i++) {
          this.dataValueY1.push(arr[i].avgDealPrice);
          this.dataValueY2.push(arr[i].avgFinalPrice);
          this.dataValueY3.push(arr[i].avgDealProfit);
          if (this.dateType == "01") {
            this.dataValueX.push(arr[i].calendarMonth + "月");
          } else if (this.dateType == "02") {
            this.dataValueX.push(arr[i].calendarQuarter + "季度");
          } else {
            this.dataValueX.push(arr[i].calendarYear + "年");
          }
        }
        this.creatEchart3();
      }
      if (this.dataType == "04") {
        for (let i = 0; i < arr.length; i++) {
          this.dataValueY1.push(arr[i].staffReward);
          this.dataValueY2.push(arr[i].dealProfit);
          if (this.dateType == "01") {
            this.dataValueX.push(arr[i].calendarMonth + "月");
          } else if (this.dateType == "02") {
            this.dataValueX.push(arr[i].calendarQuarter + "季度");
          } else {
            this.dataValueX.push(arr[i].calendarYear + "年");
          }
        }
        this.creatEchart4();
      }
      if (this.dataType == "05") {
        for (let i = 0; i < arr.length; i++) {
          // this.dataValueY1.push(arr[i].dealProfitRate);
          this.dataValueY1.push({
            upType: arr[i].thanDealProfitRateUp,
            upNum: arr[i].thanDealProfitRate,
            value: arr[i].dealProfitRate,
          });
          if (this.dateType == "01") {
            this.dataValueX.push(arr[i].calendarMonth + "月");
          } else if (this.dateType == "02") {
            this.dataValueX.push(arr[i].calendarQuarter + "季度");
          } else {
            this.dataValueX.push(arr[i].calendarYear + "年");
          }
        }
        this.creatEchart5();
      }
      if (this.dataType == "06") {
        this.dataValueY1 = [
          {
            value: arr?arr.dealRate:0,
            name: "成交率",
          },
          {
            value: arr?arr.cancelRate:0,
            name: "取消率",
          },
          {
            value: arr?arr.refundAbolishRate:0,
            name: "退款/作废率",
          },
        ];
        this.dataValueY2 = [
          {
            value: arr?arr.recycleRate:0,
            name: "独立回收",
          },
          {
            value: arr?arr.tradeRate:0,
            name: "以旧换新",
          },
        ];
        this.subText = arr?arr.dealRate + "%":'0%';
        this.creatEchart61();
        this.creatEchart62();
      }
    },
  },
  methods: {
    creatEchart1() {
      let myChart = echarts.init(document.getElementById("chartBoxDeal1"));
      let dateTypeVal =
        this.dateType == "01" ? "月" : this.dateType == "02" ? "季度" : "年";
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
          formatter: function(params) {
            var result = params[0].axisValue + "<br/>";
            params.forEach(function(item) {
              let upType = item.data.upType == "01" ? "上涨" : "减少";
              result +=
                item.marker +
                item.seriesName +
                "：" +
                item.data.value +
                "单" +
                "<br/>";
              result +=
                item.marker +
                item.seriesName +
                "较上" +
                dateTypeVal +
                upType +
                "：" +
                item.data.upNum +
                "单" +
                "<br/>";
            });
            return result;
          },
        },
        legend: {
          data: ["询价量", "成交量"],
          itemGap: 20,
          icon: "circle",
          right: "45",
          top: "20",
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.dataValueX,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单/" + dateTypeVal,
          },
        ],
        grid: {
          top: '18%',
          left: '15%',//原来是10%，修改为20%
          right: '2%',
          bottom: '24%',
        },
        series: [
          {
            name: "询价量",
            type: "bar",
            color: "#2A9CF1",
            barGap: 0,
            barWidth: 12,
            data: this.dataValueY1,
          },
          {
            name: "成交量",
            type: "bar",
            color: "#2EC7C9",
            barGap: 0,
            barWidth: 12,
            data: this.dataValueY2,
          },
        ],
      });
    },
    creatEchart2() {
      let myChart = echarts.init(document.getElementById("chartBoxDeal2"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
          formatter: function(params) {
            var result = params[0].axisValue + "<br/>";
            params.forEach(function(item) {
              result +=
                item.marker +
                item.seriesName +
                "：" +
                item.data +
                "元" +
                "<br/>";
            });
            return result;
          },
        },
        legend: {
          data: ["回收交易额", "用户成交额", "毛利贡献"],
          itemGap: 20,
          right: "45",
          top: "20",
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.dataValueX,
          },
        ],
        yAxis: [
          {
            type: "value",
            name:
              "元/" +
              (this.dateType == "01"
                ? "月"
                : this.dateType == "02"
                ? "季"
                : "年"),
          },
        ],
        grid: {
          top: '18%',
          left: '15%',//原来是10%，修改为20%
          right: '2%',
          bottom: '24%',
        },
        series: [
          {
            name: "回收交易额",
            type: "line",
            color: "#20A0FF",
            data: this.dataValueY1,
          },
          {
            name: "用户成交额",
            type: "line",
            color: "#2EC7C9",
            data: this.dataValueY2,
          },
          {
            name: "毛利贡献",
            type: "line",
            color: "#F7BA2A",
            data: this.dataValueY3,
          },
        ],
      });
    },
    creatEchart3() {
      let myChart = echarts.init(document.getElementById("chartBoxDeal3"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
          formatter: function(params) {
            var result = params[0].axisValue + "<br/>";
            params.forEach(function(item) {
              result +=
                item.marker +
                item.seriesName +
                "：" +
                item.data +
                "元" +
                "<br/>";
            });
            return result;
          },
        },
        legend: {
          data: ["回收单价", "回收客单价", "单机毛利"],
          itemGap: 20,
          right: "45",
          top: "20",
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.dataValueX,
          },
        ],
        yAxis: [
          {
            type: "value",
            name:
              "元/" +
              (this.dateType == "01"
                ? "月"
                : this.dateType == "02"
                ? "季"
                : "年"),
          },
        ],
        grid: {
          top: '18%',
          left: '15%',//原来是10%，修改为20%
          right: '2%',
          bottom: '24%',
        },
        series: [
          {
            name: "回收单价",
            type: "line",
            color: "#20A0FF",
            data: this.dataValueY1,
          },
          {
            name: "回收客单价",
            type: "line",
            color: "#2EC7C9",
            data: this.dataValueY2,
          },
          {
            name: "单机毛利",
            type: "line",
            color: "#F7BA2A",
            data: this.dataValueY3,
          },
        ],
      });
    },
    creatEchart4() {
      let myChart = echarts.init(document.getElementById("chartBoxDeal4"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
          formatter: function(params) {
            var result = params[0].axisValue + "<br/>";
            params.forEach(function(item) {
              result +=
                item.marker +
                item.seriesName +
                "：" +
                item.data +
                "元" +
                "<br/>";
            });
            return result;
          },
        },
        legend: {
          data: ["员工奖励", "公司毛利"],
          itemGap: 20,
          right: "45",
          top: "20",
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.dataValueX,
          },
        ],
        yAxis: [
          {
            type: "value",
            name:
              "元/" +
              (this.dateType == "01"
                ? "月"
                : this.dateType == "02"
                ? "季"
                : "年"),
          },
        ],
        grid: {
          top: '18%',
          left: '15%',//原来是10%，修改为20%
          right: '2%',
          bottom: '24%',
        },
        series: [
          {
            name: "员工奖励",
            type: "line",
            color: "#20A0FF",
            data: this.dataValueY1,
          },
          {
            name: "公司毛利",
            type: "line",
            color: "#F7BA2A",
            data: this.dataValueY2,
          },
        ],
      });
    },
    creatEchart5() {
      let myChart = echarts.init(document.getElementById("chartBoxDeal5"));
      let dateTypeVal =
        this.dateType == "01" ? "月" : this.dateType == "02" ? "季度" : "年";
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
          formatter: function(params) {
            var result = params[0].axisValue + "<br/>";
            params.forEach(function(item) {
              let upType = item.data.upType == "01" ? "上涨" : "减少";
              result +=
                item.marker +
                item.seriesName +
                "：" +
                item.data.value +
                "%" +
                "<br/>";
              result +=
                item.marker +
                item.seriesName +
                "较上" +
                dateTypeVal +
                upType +
                "：" +
                item.data.upNum +
                "%" +
                "<br/>";
            });
            return result;
          },
        },
        legend: {
          data: ["毛利率"],
          itemGap: 20,
          icon: "circle",
          right: "45",
          top: "20",
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.dataValueX,
          },
        ],
        yAxis: [
          {
            type: "value",
            name:
              "%/" +
              (this.dateType == "01"
                ? "月"
                : this.dateType == "02"
                ? "季"
                : "年"),
          },
        ],
        grid: {
          top: '18%',
          left: '15%',//原来是10%，修改为20%
          right: '2%',
          bottom: '24%',
        },
        series: [
          {
            name: "毛利率",
            type: "bar",
            color: "#2A9CF1",
            barGap: 0,
            barWidth: 12,
            data: this.dataValueY1,
          },
        ],
      });
    },
    creatEchart61() {
      let myChart = echarts.init(document.getElementById("chartBoxDeal6_1"));
      // 绘制图表
      myChart.setOption({
        legend: {
          itemGap: 20,
          icon: "circle",
          bottom: "0",
        },
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            return params.name + "：" + params.value + "%";
          },
        },
        title: {
          text: "成交率",
          subtext: this.subText,
          textStyle: {
            fontSize: 12,
            color: "#8492A6",
            fontWeight: 400,
          },
          subtextStyle: {
            fontSize: 16,
            color: "#333",
            fontWeight: 500,
          },
          textAlign: "center", //图例文字居中显示
          x: "48%", //距离左边的距离
          y: "42%", //距离上边的距离
        },
        color: ["#2A9CF1", "#C9D1DC", "#FF6767"],
        series: [
          {
            type: "pie",
            radius: ["60%", "75%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '16',
            //     color:"#333",
            //     formatter: function(data){ // 设置圆饼图中间文字排版
            //       var result = data.name + "\n"
            //       return result + data.value + '%'
            //     }
            //   }
            // },
            labelLine: {
              show: false,
            },
            data: this.dataValueY1,
          },
        ],
      });
    },
    creatEchart62() {
      let myChart = echarts.init(document.getElementById("chartBoxDeal6_2"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            return params.name + "：" + params.value + "%";
          },
        },
        legend: {
          itemGap: 20,
          icon: "circle",
          bottom: "0",
        },
        color: ["#40D0D2", "#F8CB60"],
        series: [
          {
            type: "pie",
            radius: "50%",
            data: this.dataValueY2,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
    //限制年份可选范围
    getYearPickerOptions(val) {
      this.startPickerOptions = {
        disabledDate: (time) => {
          return (
            time.getFullYear() < moment(val).format("YYYY") ||
            time.getFullYear() > moment(Date.now()).format("YYYY")
          );
        },
      };
      this.endPickerOptions = {
        disabledDate: (time) => {
          return (
            time.getFullYear() < moment(val).format("YYYY") ||
            time.getFullYear() > moment(Date.now()).format("YYYY")
          );
        },
      };
      let curSYear = moment(val).format("yyyy");
      let curEYear = moment(Date.now()).format("yyyy");
      console.log(curSYear, curEYear);
      if (curEYear - curSYear > 3) {
        this.curStartYear = curEYear - 3;
        this.curEndYear = curEYear;
      } else {
        this.curStartYear = curSYear;
        this.curEndYear = curEYear;
      }
    },
    // 限制季度所选范围
    getQuarterPickerOptions(val) {
      let quarterArr = ["01,02,03","04,05,06","07,08,09","10,11,12"]
      let endQuarter = ""
      let startQuarter = ""
      let endTime = moment(val).format("yyyy-MM");
      let startTime = moment(Date.now()).format("yyyy-MM");
      let endYear = (endTime.split('-'))[0]
      let startYear = (startTime.split('-'))[0]
      quarterArr.forEach((item,i) => {
        if(item.indexOf((endTime.split('-'))[1]) >= 0){
          endQuarter = (i+1)
        }
        if(item.indexOf((startTime.split('-'))[1]) >= 0){
          startQuarter = (i+1)
        }
      })
      if(startYear == endYear){
        this.quarterStartValue = endYear + '-' + endQuarter
        this.quarterEndValue = startYear + '-' + startQuarter
      }else{
        if((startQuarter - 3)>=1){
          this.quarterStartValue = startYear + '-' + 1
          this.quarterEndValue = startYear + '-' + startQuarter
        } else if((startQuarter + (5-endQuarter))>=4){
          this.quarterEndValue = startYear + '-' + startQuarter
          this.quarterStartValue = endYear + '-' + (4+(startQuarter-3))
        }else {
          this.quarterStartValue = endYear + '-' + endQuarter
          this.quarterEndValue = startYear + '-' + startQuarter
        }
        
      }
      this.quarterPickerOptions = [endYear + '-' + endQuarter,startYear + '-' + startQuarter,]
    },
    // 月份区间
    addTotalDateChange(e) {
      this.monthTime = e
      this.$emit("addTotalDate", { e, dateType: "01" });
    },
    // 选择年份
    handleChangeYear(e, type) {
      this.$emit("addTotalDate", { e, dateType: "03" });
    },
    // 选择季度
    handleChangeQuarter(e, type) {
      if (type == "start") {
        this.quarterStartValue = e;
        this.$emit("addTotalDate", {
          e: {
            startQuarter: this.quarterStartValue,
            endQuarter: this.quarterEndValue,
          },
          dateType: "02",
        });
      } else if (type == "end") {
        this.quarterEndValue = e;
        this.$emit("addTotalDate", {
          e: {
            startQuarter: this.quarterStartValue,
            endQuarter: this.quarterEndValue,
          },
          dateType: "02",
        });
      }else{
        this.$emit("addTotalDate", {
          e,
          dateType: "02",
        });
      }
    },
    // 数据处理
    numHandle(num) {
      if (num >= 9999) {
        if ((num / 10000).toString().indexOf(".") < 0) {
          return num / 10000 + "w";
        } else {
          return this.formatDecimal(num / 10000, 1) + "w+";
        }
      } else {
        return num;
      }
    },
    formatDecimal(num, decimal) {
      num = num.toString();
      let index = num.indexOf(".");
      if (index !== -1) {
        num = num.substring(0, decimal + index + 1);
      } else {
        num = num.substring(0);
      }
      num = parseFloat(num)
        .toFixed(decimal)
        .toString();
      if (num[num.indexOf(".") + 1] == 0) {
        num = num.substring(0, num.indexOf("."));
      }
      return num;
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  min-width: 550px;
  height: 570px;
  background: #ffffff;
  border-radius: 4px 0px 0px 4px;
  padding: 20px 0 0;
  margin-bottom: 14px;
  .title-box {
    .title {
      color: #333333;
      font-size: 16px;
      line-height: 18px;
      border-left: 4px solid #20a0ff;
      padding-left: 7px;
    }
    padding: 0 7px 0 0;
    box-sizing: border-box;
    /deep/ input::-webkit-input-placeholder {
      color: #a1a4af !important;
    }
    /deep/.el-input,
    /deep/.el-input__inner {
      background-color: #f9fbfd;
      border-color: #d9e6ff;
      width: 244px !important;
    }
    .data-quarter {
      /deep/.el-input,
      /deep/.el-input__inner {
        width: 127px !important;
      }
    }
    .data-year {
      /deep/.el-input,
      /deep/.el-input__inner {
        width: 114px !important;
      }
    }
    /deep/.el-input__inner.is-active {
      border-color: #45a3fc;
    }
    /deep/.el-input__inner .el-range-separator {
      width: max-content !important;
      color: #333333 !important;
    }
    /deep/.el-input__inner .el-range-input {
      background-color: #f9fbfd !important;
    }
    //显示时按钮样式
    .el-button--primary {
      //需要更改的按钮类型 type='primary'
      background: #20a0ff !important;
      border-color: #20a0ff !important;
    }
    //移入时按钮样式 type='primary'
    .el-button--primary:hover {
      background: #20a0ff !important;
      border-color: #20a0ff !important;
      color: #fff !important;
      opacity: 0.8;
    }
    .el-button--default {
      //需要更改的按钮类型 type='default'
      background: #f9fbfd !important;
      border-color: #d9e6ff !important;
      color: #8492a6 !important;
    }
    //移入时按钮样式type='default'
    .el-button--default:hover {
      background: #fff !important;
      border-color: #45a3fc !important;
      color: #20a0ff !important;
      opacity: 0.8;
    }
  }
  .card-body {
    padding: 25px 0;
    box-sizing: border-box;
    .Polyline {
      width: 100%;
      height: 350px;
    }
    .Polyline6_1 {
      width: 50%;
      height: 250px;
    }
    .Polyline6_2 {
      width: 50%;
      height: 200px;
    }
    .data-item {
      margin-left: 14px;
    }
    .compare-tag {
      height: 24px;
      border-radius: 12px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .compare-tag.red {
      background: rgba(255, 73, 73, 0.1);
      color: #ff4949;
    }
    .compare-tag.green {
      background: rgba(68, 216, 134, 0.1);
      color: #44d886;
    }
    .table_06 {
      border: 1px solid #e4ecfd;
      .table-row {
        font-size: 14px;
        height: 50px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding: 0 30px;
      }
      .table-row:not(:last-child) {
        border-bottom: 1px solid #e4ecfd;
      }
    }
  }
}
.flex {
  display: flex;
  align-items: center;
}
.flex1 {
  flex: 1;
}
.flex-as {
  display: flex;
  align-items: flex-start;
}
.flex-jb {
  display: flex;
  justify-content: space-between;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.pl-7 {
  padding-left: 7px;
}
.plr-25 {
  padding: 0 25px;
}
.mr-5 {
  margin-right: 5px;
}
.fs-12 {
  font-size: 12px;
}
.fs-16 {
  font-size: 16px;
}
.txt-333 {
  color: #333;
}
.txt-2 {
  color: #8492a6;
}
</style>
